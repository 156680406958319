<script setup lang="ts">
import type { InternetOffer, TvOffer } from "~/types/Offers";
const route = useRoute();

definePageMeta({
  title: "Internet dla Turku i okolic",
});

const { locale, setLocale, t } = useI18n();
const quickActions = [
  {
    name: t("actBtn.speedtest"),
    icon: "i-material-symbols-light:speed-outline-rounded",
    link: "/speedtest",
  },
  {
    name: t("actBtn.devices"),

    icon: "material-symbols-light:devices-wearables-outline-rounded",
    link: "/#devices",
  },
  {
    name: t("actBtn.coverage"),

    icon: "i-material-symbols-light-bigtop-updates-rounded",
    link: "/search",
  },
  {
    name: t("actBtn.documents"),

    link: "#documents",
    icon: "i-material-symbols-light:description-outline-rounded",
  },
  {
    name: t("actBtn.ibok"),

    link: "/ibok",
    icon: "i-material-symbols-light:account-circle",
  },
];
const perks = [
  {
    icon: "material-symbols:task-alt-rounded",
    heading: t("perks.0.name"),
    content: t("perks.0.content"),
  },
  {
    icon: "material-symbols:network-check-rounded",
    heading: t("perks.1.name"),
    content: t("perks.1.content"),
  },
  {
    icon: "material-symbols:acute-outline-rounded",
    heading: t("perks.2.name"),
    content: t("perks.2.content"),
  },
];

const { data: internetOffer } = await useTFetch<InternetOffer>(
  "/api/getInternetOffers?type=home",
  "int"
);
const { data: tvOffer } = await useTFetch<TvOffer>(
  "/api/getTvOffers?type=home",
  "tv"
);
</script>

<template>
  <TSwiper class="animate-fade" />
  <UContainer class="mx-auto">
    <section class="flex-preset *:animate-fade">
      <TActionCard
        v-for="({ name: label, icon, link: to }, i) in quickActions"
        :to
        :icon
        :label
        :class="
          [
            'animate-delay-50',
            'animate-delay-100',
            'animate-delay-200',
            'animate-delay-300',
            'animate-delay-[400ms]',
            'animate-delay-500',
          ][i]
        "
      />
    </section>
    <section class="flex-preset">
      <TPerkCard
        v-for="{ heading, content, icon } in perks"
        :heading
        :content
        :icon
      />
    </section>
    <section>
      <h1 class="text-heading">{{ $t("section.articles") }}</h1>
      <TArticles />
    </section>
    <section>
      <h1 class="text-heading">{{ $t("generic.offers") }}</h1>
      <div
        class="flex flex-wrap md:flex-row flex-col justify-center gap-6 *:md:min-w-[350px]"
      >
        <div class="md:basis-1/4 grow">
          <h1 class="text-center text-xl font-semibold mb-4">
            {{ $t("generic.internet") }}
          </h1>
          <TOfferToggle
            to="/internet"
            v-if="internetOffer"
            class="mx-auto"
            :internet-offer="internetOffer"
          />
        </div>
        <div class="md:basis-1/4 grow">
          <h1 class="text-center text-xl font-semibold mb-4">
            {{ $t("generic.tv") }}
          </h1>
          <TOfferToggle
            v-if="tvOffer"
            class="mx-auto"
            :tv-offer="tvOffer"
            to="/telewizja"
          />
        </div>
        <div class="md:basis-1/4 grow">
          <div
            class="py-8 px-8 shadow mt-[112px] flex flex-col items-center bg-gray-100 rounded-2xl gap-6"
          >
            <h1 class="text-xl font-semibold">{{ $t("generic.forCom") }}</h1>
            <p>
              {{ $t("offers.forComDesc") }}
            </p>
            <UButton size="lg" to="/internet-dla-firm">{{
              $t("cta.checkOffer")
            }}</UButton>
          </div>
        </div>
      </div>
      <p class="text-disclaimer">
        {{ $t("disclaimers.offer") }}
      </p>
    </section>
    <section>
      <div class="flex flex-wrap flex-article animate-children-fade">
        <div class="card p-6 flex-col max-md:flex items-center">
          <h2 class="!text-2xl inline-flex items-center gap-1">
            <UIcon
              class="align-middle"
              name="material-symbols-search-rounded"
            /><span> {{ $t('generic.search') }} </span>
          </h2>
          <p class="text-lg max-md:!text-center">
            {{ $t('search.short') }}
          </p>
          <UButton to="/search" class="mt-4" size="lg">{{ $t('cta.check') }}</UButton>
        </div>
        <img loading="lazy" src="/img/search.jpg" alt="" />
        <img loading="lazy" src="/img/intPerks2.jpg" alt="" />
        <div class="card p-6 flex-col max-md:flex items-center">
          <h2 class="!text-2xl inline-flex items-center gap-1">
            <UIcon
              class="align-middle"
              name="material-symbols-speed-outline-rounded"
            /><span>{{ $t('actBtn.speedtest') }}</span>
          </h2>
          <p class="text-lg max-md:!text-center">
            {{ $t('generic.speedtest') }}
          </p>
          <UButton to="/speedtest" class="mt-4" size="lg">{{ $t('cta.check') }}</UButton>
        </div>
      </div>
    </section>

  </UContainer>
</template>

<style></style>
