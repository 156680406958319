import type { Nullish } from "~/types/Helpers";

export const useTFetch = async<T>(request: Parameters<typeof $fetch>[0], key: string, options?: Parameters<typeof $fetch>[1]) => useAsyncData(
  key,
  () =>
    $fetch<Nullish<T>>(request, options),
  {
    server: true,
    deep: false,
    dedupe: "defer",
  }
);
