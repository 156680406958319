<script setup lang="ts">
const { data } = await useFetch("/api/getSwiperCards", {
  deep: false,
  server: true,
});
import { Parallax, Pagination, Navigation } from "swiper/modules";
const modules = [Parallax, Pagination, Navigation];
</script>

<template>
  <swiper
    :style="{
      '--swiper-navigation-color': '#ffffff50',
      '--swiper-pagination-color': '#ffffff50',
    }"
    :slides-per-view="1"
    navigation
    :parallax="true"
    class="w-full h-[480px]"
    :modules="modules"
    :autoplay="true"
    pagination
  >
    <div
      slot="container-start"
      class="select-none absolute z-0 top-0 left-[-5%] w-[135%] h-full bg-cover bg-center"
      :style="{
        'background-image': 'url(/img/bg.jpeg)',
      }"
      data-swiper-parallax="-20%"
    ></div>
    <swiper-slide v-for="card in data">
      <div class="flex items-center justify-center w-full h-full">
        <div class="flex flex-col gap-4 z-20 relative w-[75%] lg:w-auto">
          <h1
            class="text-3xl font-semibold text-pretty md:text-4xl text-primary-50"
          >
            {{ card.title }}
          </h1>
          <p
            class="max-w-3xl text-base text-pretty md:text-left md:text-lg text-primary-50"
          >
            {{ card.content }}
          </p>

          <UButton
            :to="card.link"
            class="w-fit mx-auto md:m-0"
            size="lg"
            external
          >
            {{ card.cta ?? $t("cta.checkOffer") }}
          </UButton>
        </div>
      </div>
    </swiper-slide>
  </swiper>
</template>
