<script setup lang="ts">
import type { Article } from "~/types/Articles";

// const props = defineProps<{
//   articles?: Article[];
// }>();
const token = import.meta.server ? "1111" : "0";

const { data: articles } = await useAsyncData(
  "posts",
  () =>
    $fetch("/api/getArticles", {
      headers: {
        Authorization: "Basic " + btoa(token),
      },
    }),
  {
    server: true,
    deep: false,
    dedupe: "defer",
  }
);
const selectedArticle = ref<Article>(
  articles.value ? articles.value[0] : ({} as Article)
);
</script>

<template>
  <div
    v-if="articles && articles.length > 0"
    class="w-full min-h-96 flex flex-wrap gap-4"
  >
    <div class="basis-full md:basis-1/2 grow flex flex-col gap-4 ">
      <div class="grow h-1/3">
        <img
          src="/img/artbg.png"
          class="w-full h-full object-cover object-center rounded-2xl"
          alt=""
        />
      </div>
      <div class="grow h-1/3 rounded-2xl">
        <p>{{ new Date(selectedArticle.published).toLocaleDateString("pl-Pl") }}</p>
        <h1 class="text-2xl font-semibold">{{ selectedArticle.title }}</h1>
        <p class="text-lg">{{ selectedArticle.content }}</p>
      </div>
    </div>
    <div
      class="basis-1/4 min-w-full md:min-w-[300px] flex flex-col h-full overflow-y-auto max-h-96  gap-4"
    >
      <div
        role="button"
        tabindex="0"
        v-for="article in articles"
        @click="selectedArticle = article"
        :class="{ 'border-primary-500': article.id == selectedArticle.id }"
        class="w-full bg-gray-100 border hover:bg-primary-100 transition-colors px-6 py-4 rounded-xl"
      >
        <p>{{ new Date(article.published).toLocaleDateString("pl-Pl") }}</p>
        <p class="text-lg font-medium truncate overflow-hidden w-full">
          {{ article.title }}
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
